import router from 'next/router'
import React from 'react'
import useMediaQuery from '../../utils/publicApi'
import Button from '../newButton/Button'
import styles from './error404.module.scss'

const Error404 = () => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const handleBack = () => {
    return router.push('/')
  }
  return (
    <div className={styles.container}>
      <div className={styles.containerWrapper}>
        <div className={styles.containerImgWrapper}>
          <div className={styles.errorImge} role='image' aria-label='Error 404 - Back to home ' />
          <div className={styles.infoText}>
            <h1>Whoops! This page has gone off-leash 🐾 </h1>
            <p> Check the search bar for any typos or return to our Home page.</p>
            <Button
              theme={isMobile ? ['short', 'pink-solid'] : ['large', 'pink-solid']}
              title='Return to home'
              onClick={handleBack}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404
