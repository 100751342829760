import React from 'react'
import styles from './error404.module.scss'

const Error500 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.containerWrapper}>
        <div className={styles.containerImgWrapper}>
          <div className={styles.errorImge} role='image' aria-label='Error 500 - Back to home ' />
          <div className={styles.infoText}>
            <h1>Oops, it seems our puppies have chewed through a wire or two! 🐾</h1>
            <p>Please try again or refresh the page!</p>
            <p>Still having trouble? Contact our support team – they're always ready to lend a paw!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error500
