import React from 'react'
import NextErrorComponent from 'next/error'
import Header from '../components/newHeader/Header'
import Error404 from '../components/error/error404'
import Error500 from '../components/error/error500'

// Component for Layout Error 404
const NotFoundLayout = () => (
  <div>
    <Header />
    <Error404 />
  </div>
)

// Component for Layout Error 500
const ServerErrorLayout = () => (
  <div>
    <Header />
    <Error500 />
  </div>
)

const MyError = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
  }

  if (statusCode === 404) {
    return <NotFoundLayout />
  } else if (statusCode === 500) {
    return <ServerErrorLayout />
  }

  return <NextErrorComponent statusCode={statusCode} />
}

MyError.getInitialProps = async ({ res, err }) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  })

  errorInitialProps.hasGetInitialPropsRun = true

  if (res?.statusCode === 404) {
    return { statusCode: 404 }
  } else if (res?.statusCode === 500) {
    return { statusCode: 500 }
  }

  return errorInitialProps
}

export default MyError
